import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "intro-videos"
    }}>{`Intro videos`}</h1>
    <p>{`Watch these videos before the workshop. They'll help you gain a base understanding and make the workshop go smoother.`}</p>
    <p>{`That way we can focus on fun practice instead of boring lectures 😊`}</p>
    <h2 {...{
      "id": "why-serverless"
    }}>{`Why serverless`}</h2>
    <Vimeo id={462692235} mdxType="Vimeo" />
    <h2 {...{
      "id": "which-serverless"
    }}>{`Which serverless`}</h2>
    <Vimeo id={462692575} mdxType="Vimeo" />
    <p>{`Lots of choice in the serverless world. Which provider should you choose and why? What are some of the differences?`}</p>
    <h2 {...{
      "id": "what-is-a-cloud-function"
    }}>{`What is a cloud function`}</h2>
    <Vimeo id={463067107} mdxType="Vimeo" />
    <p>{`Cloud functions are what most people think of when talking about serverless. But what are they?`}</p>
    <h2 {...{
      "id": "anatomy-of-a-cloud-function"
    }}>{`Anatomy of a cloud function`}</h2>
    <Vimeo id={463067267} mdxType="Vimeo" />
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8e81e7e69e80bc31627c19d948fbccd7/81501/cloud-function-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "64.1255605381166%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'257\\'%20viewBox=\\'0%200%20400%20257\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M192%2033c-3%201-3%203%201%202%209-2%2018%203%2018%2012%200%204%200%204-12%2012-8%205-13%209-13%2011l2-1%2020-13%205-3v4l2%206c2%202%2010%208%2011%207l-7-6c-1%200-3-5-5-13%200-5-2-10-3-12-3-5-13-8-19-6m-20%2047a330%20330%200%2001-42%202c1%201%2029%201%2047-1%2026-2%2086%201%2088%204%201%202%201%2015-1%2031l-2%2026v12l-35-1a1079%201079%200%2000-99%205l2%201c6-3%2082-5%20116-4l15%201v2l1%203%202-14%202-32c2-21%203-30%201-32-4-3-74-5-95-3m151%201l-1%204-1%204v1l3-2c0-2%206-2%2011%200%207%202%2010-3%203-6-4-2-6-2-6%201%200%202-1%201-3-1-2-3-5-4-6-1m34%201c-3%203-4%203-4%201%200-3-1-3-4%201l-4%203c-2%200-2%202%200%203l4-2c1-2%202-3%203-2h4c2-2%203-2%204%200%201%201%201%201-2%201-2%200-2%200-1%202h8c1-1%200-2-2-3l-2-2%203-1%203-2-3-1h-3c0-2-1-1-4%202m-232-1l1%207%201%208c0%203%200%203-2%201s-7-4-15-5c-9-1-6%202%202%203%207%201%2013%203%2013%205l-10%206c-8%203-12%206-9%206l19-8%202-3v15a1024%201024%200%20001-33c0-3-2-4-3-2m57%2011c-3%205-2%208%203%208%204%200%2010-4%2010-6%200-1-6-5-9-5l-4%203m167%204c-1%202-2%202%209%203%2015%203%2018%206%206%207-8%201-16%203-15%205h4l11-2c15-2%2013-8-4-11l-9-2h-2m-233%204a4140%204140%200%2001-51%205c-37%205-43%206-41%209h1a536%20536%200%200191-14m114%2068c-3%208-3%2012-1%2013v-3c0-2%201-6%202-5%203%204%2013%2034%2013%2039%200%203%201%205%202%203%201-1-3-16-8-28-6-18-8-29-2-16l3%204c1%200-5-13-7-13l-2%206M89%20217c-1%201%200%203%201%203s2%202%202%205v5h5c4%200%205-1%205-3%200-5-11-13-13-10\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/8e81e7e69e80bc31627c19d948fbccd7/ca0a1/cloud-function-anatomy.webp 223w", "/static/8e81e7e69e80bc31627c19d948fbccd7/75680/cloud-function-anatomy.webp 445w", "/static/8e81e7e69e80bc31627c19d948fbccd7/8d1ba/cloud-function-anatomy.webp 890w", "/static/8e81e7e69e80bc31627c19d948fbccd7/3838e/cloud-function-anatomy.webp 1335w", "/static/8e81e7e69e80bc31627c19d948fbccd7/e11e5/cloud-function-anatomy.webp 1780w", "/static/8e81e7e69e80bc31627c19d948fbccd7/d1dc7/cloud-function-anatomy.webp 2886w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/8e81e7e69e80bc31627c19d948fbccd7/e92b6/cloud-function-anatomy.png 223w", "/static/8e81e7e69e80bc31627c19d948fbccd7/e66bf/cloud-function-anatomy.png 445w", "/static/8e81e7e69e80bc31627c19d948fbccd7/4ef49/cloud-function-anatomy.png 890w", "/static/8e81e7e69e80bc31627c19d948fbccd7/4e814/cloud-function-anatomy.png 1335w", "/static/8e81e7e69e80bc31627c19d948fbccd7/701e9/cloud-function-anatomy.png 1780w", "/static/8e81e7e69e80bc31627c19d948fbccd7/81501/cloud-function-anatomy.png 2886w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/8e81e7e69e80bc31627c19d948fbccd7/4ef49/cloud-function-anatomy.png",
                "alt": "Anatomy of a cloud function",
                "title": "Anatomy of a cloud function",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Anatomy of a cloud function`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "important-caveats"
    }}>{`Important caveats`}</h2>
    <Vimeo id={463067355} mdxType="Vimeo" />
    <h2 {...{
      "id": "serverless-is-an-ecosystem"
    }}>{`Serverless is an ecosystem`}</h2>
    <Vimeo id={463067668} mdxType="Vimeo" />
    <p>{`Beyond cloud functions, what else can serverless do for you? What's the benefit of going with this approach?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      